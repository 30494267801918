import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import axios from 'axios';

import global from 'window-or-global';

const AuthListener = ({ children, authRequired }) => {
  const [loaded, setLoaded] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get('/api/v1/auth-status');
        const { data } = response;

        // console.log(data);

        setAuthenticated(data.authenticated);
      } finally {
        setLoaded(true);
      }
    })();
  }, []);

  // console.log(authRequired);

  if (!loaded) {
    return null;
  }

  if (!authenticated && authRequired) {
    const searchParams = new URLSearchParams();

    searchParams.set('client_id', '713634806017294348');
    searchParams.set('redirect_uri', `${global.location.origin}/api/v1/discord-auth`);
    searchParams.set('response_type', 'code');
    searchParams.set('scope', 'identify');

    const linkUri = `https://discord.com/api/oauth2/authorize?${searchParams.toString()}`;

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <a href={linkUri}>Log In</a>
      </div>
    );
  }

  return children;
};

AuthListener.propTypes = {
  children: PropTypes.node.isRequired,
  authRequired: PropTypes.bool,
};

AuthListener.defaultProps = {
  authRequired: false,
};

export default AuthListener;
