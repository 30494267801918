import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { io } from 'socket.io-client';

import { useGlobalContext } from 'hooks';

// Do not fuck with this file.
// If you fuck with this file, you are responsible for what you break.
const SocketListener = ({ children }) => {
  const socketRef = useRef(null);
  const store = useGlobalContext();
  const router = useRouter();

  useEffect(() => {
    socketRef.current = io('', { path: '/api/v1/realtime' });

    const { current: socket } = socketRef;

    socket.on('connect', () => {
      socket.emit('join', 'state');
      socket.emit('ping');
    });

    socket.on('pong', () => {
      // eslint-disable-next-line no-console
      // console.log('Pong received!');
    });

    socket.on('update', (data, dataHash) => {
      if (dataHash === store.lastReceivedHash || dataHash === store.hash) {
        return;
      }

      store.updating = true;
      store.lastReceivedHash = dataHash;

      runInAction(() => {
        for (const [k, v] of Object.entries(data)) {
          store[k] = v;
        }
      });

      store.updating = false;
    });

    socket.on('cleared', () => {
      if (router.pathname === '/clear') {
        router.replace('/');
      } else {
        router.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (store.updating || !store.lastReceivedHash || store.hash === store.lastReceivedHash) {
      return;
    }

    // This has to be sent stringified or else socket.io will trigger an observer recursion loop
    socketRef.current.emit('update', store.stringified, store.hash);
    store.lastReceivedHash = store.hash;
  }, [socketRef.current, store.hash, store.updating]);

  return children;
};

SocketListener.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(SocketListener);
